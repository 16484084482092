var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{ref:"sidebar",attrs:{"id":"vendor-details-sidebar","width":"50%","bg-variant":"white","right":"","backdrop":"","no-header":""},on:{"hidden":function($event){return _vm.resetData()},"shown":function($event){return _vm.getVendorData()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.isObjectEmpty(_vm.vendorInfo) ? 'Edit Vendor': 'Add Vendor')+" ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"vendor-form"},[_c('b-form',{staticClass:"p-1",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Name","label-for":"Name"}},[_c('b-form-input',{attrs:{"id":"Name"},model:{value:(_vm.vendorDetail.name),callback:function ($$v) {_vm.$set(_vm.vendorDetail, "name", $$v)},expression:"vendorDetail.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('b-form-input',{attrs:{"id":"email"},model:{value:(_vm.vendorDetail.email),callback:function ($$v) {_vm.$set(_vm.vendorDetail, "email", $$v)},expression:"vendorDetail.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"phone","rules":("phone:" + (_vm.vendorDetail.phone_code))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Phone","label-for":"phone"}},[_c('b-input-group',[_c('b-input-group-prepend',[_c('b-dropdown',{staticClass:"p-0",attrs:{"variant":"outline-primary","text":_vm.formatPhoneCode()}},[_c('b-dropdown-item',{on:{"click":function($event){_vm.vendorDetail.phone_code = '880'}}},[_vm._v(" +880 ")]),_c('b-dropdown-item',{on:{"click":function($event){_vm.vendorDetail.phone_code = '92'}}},[_vm._v(" +92 ")])],1)],1),_c('b-form-input',{attrs:{"id":"phone","type":"number"},model:{value:(_vm.vendorDetail.phone_number),callback:function ($$v) {_vm.$set(_vm.vendorDetail, "phone_number", $$v)},expression:"vendorDetail.phone_number"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"region","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Region","label-for":"region"}},[_c('b-input-group',[_c('v-select',{staticClass:"w-100",attrs:{"id":"region","options":[{value: 'BD', label: 'Bangladesh'}, {value: 'PK', label: 'Pakistan'}],"reduce":function (region) { return region.value; },"clearable":false},model:{value:(_vm.vendorDetail.region),callback:function ($$v) {_vm.$set(_vm.vendorDetail, "region", $$v)},expression:"vendorDetail.region"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mr-1",attrs:{"type":"submit","variant":"primary","disabled":_vm.isBtnLoading},on:{"click":_vm.validateForm}},[(_vm.isBtnLoading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Submit ")],1)],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }